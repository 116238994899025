import {combineReducers, configureStore} from "@reduxjs/toolkit";
import productsReducer from "./slices/productsSlice";
import productBySlugReducer from "./slices/productBySlugSlice";
import articlesReducer from "./slices/articlesSlice";
import articleBySlugReducer from "./slices/articleBySlugSlice";
import cartReducer from "./slices/cartSlice";

const RootReducer = combineReducers({
  products: productsReducer,
  product: productBySlugReducer,
  articles: articlesReducer,
  article: articleBySlugReducer,
  cart: cartReducer
})

export function setupStore() {
  return configureStore({
    reducer: RootReducer,
    devTools: process.env.NODE_ENV !== 'production',
  })
}

export type RootState = ReturnType<typeof RootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore["dispatch"]