import React from 'react';
import {IProduct} from "../models/models";
import {useNavigate} from "react-router-dom";

interface ProductCardProps {
  product: IProduct;
}

const ProductCard = ({product}: ProductCardProps) => {
  const navigate = useNavigate()
  const clickHandler = () => navigate(`/products/${product.slug}`)

  return (
    <div className="w-full max-w-[300px] flex flex-col items-start border rounded-md hover:shadow-md hover:transition-all cursor-pointer" onClick={clickHandler}>
      <div className="w-full h-48 flex justify-center items-center">
        <img src={product.image} alt={product.title}/>
      </div>
      <h3 className="text-xl pt-2 font-bold">{product.title}</h3>
      <span className="text-lg">{product.price} рублей</span>
    </div>
  );
};

export default ProductCard;