import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IProduct} from "../../models/models";

export interface ProductsState {
  loading: boolean;
  error: string | null;
  products: IProduct[];
}

const initialState: ProductsState = {
  loading: false,
  error: null,
  products: []
}

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    fetchProducts(state: ProductsState) {
      state.loading = true;
    },
    fetchProductsSuccess(state: ProductsState, action: PayloadAction<IProduct[]>) {
      state.loading = false;
      state.products = action.payload;
    },
    fetchProductsError(state: ProductsState, action: PayloadAction<Error>) {
      state.loading = false;
      state.error = action.payload.message;
    }
  }
})

export default productsSlice.reducer;