import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IArticle} from "../../models/models";

export interface ArticleBySlugState {
  loading: boolean;
  error: string | null;
  article: IArticle[];
}

const initialState: ArticleBySlugState = {
  loading: false,
  error: null,
  article: []
}

export const articleBySlugSlice = createSlice({
  name: 'article',
  initialState,
  reducers: {
    fetchArticleBySlug(state: ArticleBySlugState) {
      state.loading = true;
    },
    fetchArticleBySlugSuccess(state: ArticleBySlugState, action: PayloadAction<IArticle[]>) {
      state.loading = false;
      state.article = action.payload;
    },
    fetchArticleBySlugError(state: ArticleBySlugState, action: PayloadAction<Error>) {
      state.loading = false;
      state.error = action.payload.message;
    }
  }
})

export default articleBySlugSlice.reducer;