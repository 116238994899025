import React from 'react';
import {Helmet} from "react-helmet";

const ContactsPage = () => {
  return (
    <div>
      <Helmet>
        <title>Миропристон и Миролют - Контакты, адрес магазина, доставка курьером</title>
        <meta name="description"
              content="Заказать Миропристон, Миролют с доставкой курьером по Москве и всей России. Контакты магазина. Возможен самовывоз."/>
      </Helmet>
      <div className='mx-3 md:container md:mx-auto pt-5'>
        <div className='flex flex-col justify-center'>
          <h1 className="font-bold">Контакты</h1>
          <h2>Пункт самовывоза:</h2>
          <p>Москва, м. «Бунинская Аллея», ул. Кадырова, д. 1.</p>
          <h2>Время работы:</h2>
          <p>Пн—пт: 09:00—23:00 <br/>
            Сб, Вс: 10:00—20:00</p>
          {/*<h2>Телефон для связи:</h2>*/}
          {/*<a className="text-blue-500 no-underline hover:underline hover:text-red-500 visited:text-purple-600"*/}
          {/*   href='tel:+74992868074'> +7 (499) 286-80-74</a>*/}
        </div>
        <div className='max-w-sm'>
          <iframe title='yandexMap' src="https://yandex.ru/map-widget/v1/-/CCUv4DWpwB" width="560" height="400"></iframe>
        </div>
      </div>
    </div>
  );
};

export default ContactsPage;