import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../hook/redux";
import {fetchProductBySlug} from "../store/actions/productActions";
import ProductDetailCard from "../components/ProductDetailCard";

const ProductDetailPage = () => {
  const dispatch = useAppDispatch();
  const params: any = useParams<'slug'>()
  useEffect(() => {
    dispatch(fetchProductBySlug(params.slug))
  }, [dispatch, params.slug])
  const {product, loading, error} = useAppSelector(state => state.product);
  return (
    <>
      {loading && <div className={"text-center text-lg"}>Loading...</div>}
      {error && <div className={"text-center text-lg text-red-600"}>{error}</div>}
      { !loading &&
        product.map(product => <ProductDetailCard product={product} key={product.slug}/>)
      }
    </>
  );
};

export default ProductDetailPage;