import React, {useEffect} from 'react';
import ProductCard from "../components/ProductCard";
import {useAppDispatch, useAppSelector} from "../hook/redux";
import {fetchProducts} from "../store/actions/productActions";
import {Helmet} from "react-helmet";

const ProductsPage = () => {
  const dispatch = useAppDispatch();
  const {error, loading, products} = useAppSelector(state => state.products);

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch])

  return (
    <>
      <Helmet>
        <title>Миропристон и Миролют - Интернет магазин</title>
        <meta name="description"
              content="Купить Миропристон (3 таблетки) и Миролют (4 таблетки) в Москве для медикаментозного прерывания беременности. Фармаборт до 42 дней."/>
      </Helmet>
      <div className='md:container md:mx-auto px-5'>
        <h1 className="font-bold pl-3">Магазин товаров</h1>
        {loading && <div className={"text-center text-lg"}>Loading...</div>}
        {error && <div className={"text-center text-lg text-red-600"}>{error}</div>}
        <div className='mx-auto pt-5 grid md:grid-cols-3 sm:grid-cols-2 gap-[30px]'>
          {!loading &&
            products.map(product => <ProductCard key={product.slug} product={product}/>)
          }
        </div>
      </div>
    </>
  );
};

export default ProductsPage;