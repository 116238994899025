import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {useAppSelector} from "../hook/redux";
import {ICart} from "../models/models";
import {AiOutlineClose, AiOutlineMenu} from 'react-icons/ai';

const Navigation = () => {
  const {items, totalPrice} = useAppSelector(state => state.cart)
  const totalCount = items.reduce((sum: number, item: ICart) => sum + item.count, 0);
  const [nav, setNav] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const location = useLocation();
  const links = [
    {to: '/products', label: 'Магазин'},
    // {to: '/contacts', label: 'Контакты'},
    // {to: '/pay', label: 'Оплата'},
    {to: '/base', label: 'Статьи'}
  ]
  const handleNav = () => {
    setNav(!nav);
    setIsNew(false);
  };
  useEffect(() => {
    setNav(false);
    totalCount !== 0 ? setIsNew(true) : setIsNew(false);
    if (location.pathname === '/cart') {
      setIsNew(false);
    }
  }, [location, totalCount])

  return (
    <div
      className='flex justify-between items-center h-[50px] mx-auto px-4 bg-gradient-to-r from-regal-blue to-regal-green shadow-md'>
      <Link to='/' className='w-full font-bold'>Miropriston</Link>
      <ul className='hidden md:flex items-center'>
        {
          links.map((link, index) => (
            <li className='mr-5' key={index}><Link to={link.to}>{link.label}</Link></li>
          ))
        }
        {/*<li className='mr-5'>*/}
        {/*  <a href="tel:+74992868074">+74992868074</a>*/}
        {/*</li>*/}
        <li className="flex leading-6 font-semibold text-base bg-green-500 rounded-lg p-1 text-white my-2">
          <button>
            <Link to="/cart">
              <span>{totalPrice}₽</span>
              <span className="mx-1 w-px h-6 bg-white opacity-25"></span>
              <span>{totalCount}</span>
            </Link>
          </button>
        </li>
      </ul>
      <div onClick={handleNav} className='text-green-500 block md:hidden relative'>
        {nav ? <AiOutlineClose size={20}/> : <AiOutlineMenu size={20}/>}
        {isNew ? <span className='absolute text-white bg-red-400 rounded-full w-3 h-3 top-[-5px] right-[-5px]'></span> : ''}
      </div>
      <ul
        className={nav ? 'fixed z-10 left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-gray-200 ease-in-out duration-500' : 'ease-in-out duration-500 fixed left-[-100%]'}>
        <Link to='/' className='w-full text-3xl font-bold text-green-500 m-4'>Miropriston</Link>
        {
          links.map((link, index) => (
            <li className='p-4 border-b border-gray-600' key={index}><Link to={link.to}>{link.label}</Link></li>
          ))
        }
        {/*<li className='p-4 border-b border-gray-600'>*/}
        {/*  <a href="tel:+74992868074">+74992868074</a>*/}
        {/*</li>*/}
        <li
          className="text-center w-1/3 leading-6 font-semibold bg-green-500 rounded-lg text-white my-4 ml-2 p-1 border-gray-600">
          <button>
            <Link to="/cart">
              <span>{totalPrice}₽</span>
              <span className="mx-1 w-px h-6 bg-white opacity-25"></span>
              <span>{totalCount}</span>
            </Link>
          </button>
        </li>
      </ul>
    </div>
  );
};

export default Navigation;