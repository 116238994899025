import React from 'react';
import {Helmet} from 'react-helmet'
import Intro from "../components/Intro";

const MainPage = () => {
  return (
    <div>
      <Helmet>
        <title>Миропристон и Миролют - купить, цена</title>
        <meta name="description"
              content="Купить Миропристон (3 таблетки) и Миролют (4 таблетки) в Москве для медикаментозного прерывания беременности. Фармаборт до 42 дней."/>
      </Helmet>
      <Intro/>
    </div>
  );
};

export default MainPage;