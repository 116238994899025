import React from "react";
import {IArticle} from "../models/models";
import {useNavigate} from "react-router-dom";
import classes from "./ArticleCard.module.css";

interface ArticleCardProps {
  article: IArticle
  index: number
}

const ArticleCard = ({article, index}: ArticleCardProps) => {
  const navigate = useNavigate()
  const clickHandler = () => navigate(`/base/${article.slug}`)

  return (
    <div className={classes.article} onClick={clickHandler}>
      <p className="text-lg">{index + 1}. {article.shortTitle}</p>
    </div>
  );
};

export default ArticleCard