import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IProduct} from "../../models/models";

export interface ProductBySlugState {
  loading: boolean;
  error: string | null;
  product: IProduct[];
}

const initialState: ProductBySlugState = {
  loading: false,
  error: null,
  product: []
}

export const productBySlugSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    fetchProductBySlug(state: ProductBySlugState) {
      state.loading = true;
    },
    fetchProductBySlugSuccess(state: ProductBySlugState, action: PayloadAction<IProduct[]>) {
      state.loading = false;
      state.product = action.payload;
    },
    fetchProductBySlugError(state: ProductBySlugState, action: PayloadAction<Error>) {
      state.loading = false;
      state.error = action.payload.message;
    }
  }
})

export default productBySlugSlice.reducer;