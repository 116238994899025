import {AppDispatch} from "../index";
import {articlesSlice} from "../slices/articlesSlice";
import sanityClient from "../../sanitySetup";
import {articleBySlugSlice} from "../slices/articleBySlugSlice";

export const fetchArticles = () => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(articlesSlice.actions.fetchArticles());
      const response = await sanityClient.fetch(
        `*[_type == 'article']{ 
                _id,                  
                body,
                "image" : image.asset->url,
                title,
                shortTitle,
                "slug": slug.current,
                seoDescription,
            }`
      );
      dispatch(articlesSlice.actions.fetchArticlesSuccess(response));
    } catch (error) {
      dispatch(articlesSlice.actions.fetchArticlesError(error as Error));
    }
  }
}

export const fetchArticleBySlug = (slug: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(articleBySlugSlice.actions.fetchArticleBySlug());
      const response = await sanityClient.fetch(
        `*[_type == 'article' && slug.current == '${slug}']{
                _id,                  
                body,
                "image" : image.asset->url,
                title,
                shortTitle,
                "slug": slug.current,
                seoDescription
            }`
      );
      dispatch(articleBySlugSlice.actions.fetchArticleBySlugSuccess(response));
    } catch (error) {
      dispatch(articleBySlugSlice.actions.fetchArticleBySlugError(error as Error));
    }
  }
}