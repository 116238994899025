import React from 'react';
import {Link} from "react-router-dom";
import PageNotFound from '../assets/images/Page404.png';

const NotFoundPage = () => {
  return (
    <div className='max-w-[800px] mt-[-50px] w-full h-screen mx-auto text-center flex flex-col justify-center'>
      <img alt='404' src={PageNotFound}/>
      <Link className='bg-green-400 hover:bg-green-500 w-[200px] text-white font-medium rounded-md my-6 mx-auto py-3' to="/">На
        главную</Link>
    </div>
  );
};

export default NotFoundPage;