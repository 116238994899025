import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../hook/redux";
import {fetchArticleBySlug} from "../store/actions/articleActions";
import {PortableText} from '@portabletext/react';
import {Helmet} from "react-helmet";

const ArticleDetailPage = () => {
  const dispatch = useAppDispatch();
  const params: any = useParams<'slug'>()
  useEffect(() => {
    dispatch(fetchArticleBySlug(params.slug))
  }, [dispatch, params.slug])
  const {article, loading, error} = useAppSelector(state => state.article);
  const title = article.map(a => a.title).join('');
  const description = article.map(a => a.seoDescription).join('');
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description}/>
      </Helmet>
      {loading && <div className={"text-center text-lg"}>Loading...</div>}
      {error && <div className={"text-center text-lg text-red-600"}>{error}</div>}
      {!loading &&
        article.map(a => <div className='md:container md:mx-auto pt-5 mb-10' key={a.slug}>
          <h1 className='font-bold pt-4 text-center'>{a.title}</h1>
          {a.image ? <img className='w-1/2 pt-5 pb-5' src={a.image} alt={a.title}/> : false}
          <div>
            <PortableText value={a.body}></PortableText>
          </div>
        </div>)
      }
    </>
  );
};

export default ArticleDetailPage;