import React from 'react';
import {useTypingText} from "../hook/useTypingText";
import {Link} from "react-router-dom";
import classes from "./Intro.module.css";

const Intro = () => {
  const {words} = useTypingText(
    ["врачей", "пациентов", "медицинских учреждений"],
    70,
    20
  );
  return (
    <div className='max-w-[800px] mt-[-50px] w-full h-screen mx-auto text-center flex flex-col justify-center'>
      <p className='text-gray-500 font-bold p-2 uppercase'>
        купить оригинальный комплект препаратов в Москве и по России
      </p>
      <h1 className='md:text-6xl sm:text-5xl text-4xl font-bold md:py-6'>
        Миропристон и Миролют
      </h1>
      <div className='flex justify-center items-center'>
        <p className='md:text-4xl sm:text-3xl text-xl font-bold py-4 text-gray-800'>
          надежно, быстро и доступно для <span className={classes.word}>{words}</span>
        </p>
      </div>
      <p className='md:text-2xl sm:text-md text-xl font-bold text-gray-500'>Закажите доставку, если срок по УЗИ меньше 7
        недель эффективность 98%</p>
      <Link to="/products"
            className='bg-green-400 hover:bg-green-500 w-[200px] text-white font-medium rounded-md my-6 mx-auto py-3'>Купить
      </Link>
    </div>
  );
};

export default Intro;
