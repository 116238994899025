import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IArticle} from "../../models/models";

export interface ArticlesState {
  loading: boolean;
  error: string | null;
  articles: IArticle[];
}

const initialState: ArticlesState = {
  loading: false,
  error: null,
  articles: []
}

export const articlesSlice = createSlice({
  name: 'articles',
  initialState,
  reducers: {
    fetchArticles(state: ArticlesState) {
      state.loading = true;
    },
    fetchArticlesSuccess(state: ArticlesState, action: PayloadAction<IArticle[]>) {
      state.loading = false;
      state.articles = action.payload;
    },
    fetchArticlesError(state: ArticlesState, action: PayloadAction<Error>) {
      state.loading = false;
      state.error = action.payload.message;
    }
  }
})

export default articlesSlice.reducer;