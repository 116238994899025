import React, {useState} from 'react';
import CartItem from "../components/CartItem";
import {useAppSelector} from "../hook/redux";
import CartEmpty from "../components/CartEmpty";
import OrderCardForm from "../components/OrderCardForm";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";

const CartPage = () => {
  const {items, totalPrice} = useAppSelector(state => state.cart)
  const [isReady, setReady] = useState(false)
  const [isLegal, setLegal] = useState(false)
  // const onSubmit = () => setReady(true)
  const onSubmit = () => setLegal(true)

  if (!totalPrice) {
    return <CartEmpty/>;
  }
  return (
    <>
      <Helmet>
        <title>Миропристон и Миролют - Корзина</title>
        <meta name="description"
              content="Оформите заказ на Миропристон 600 мг и Миролют 800 мкг"/>
      </Helmet>
      <div className='container mx-auto max-w-[760px] pt-5'>
        <h1 className="font-bold mb-5">Корзина</h1>
        <div>
          {
            items.map((item, index) => <CartItem key={index} index={index} item={item}/>)
          }
          <div className='flex flex-row justify-end pt-5'>
            {
              items.length > 0 &&
              <button onClick={onSubmit}
                      className='w-1/5 h-[40px] bg-green-400 hover:bg-green-500 text-white font-bold rounded-lg'
                      type="submit">Продолжить
              </button>
            }
          </div>
          {
            isReady && <OrderCardForm/>
          }
          {
            isLegal && (
              <div>
                <div className='h-28 flex justify-center items-center shadow-md'>
                  <h2 className='uppercase text-xl font-bold text-center'>Вы должны это прочитать</h2>
                </div>
                <div className='pt-3'>
                  <p>
                    <span className='font-bold'>Внимание!</span> В соответствии c идиотскими законами на територии РФ мы
                    вынуждены приостановить свою работу так как это противоречит законодательству РФ.
                    Мы приняли решение оставить <Link className='text-blue-400 underline' to='/base'>статьи</Link> для Вас, чтобы Вы могли ознакомиться с ними. Но заказы мы больше
                    принимать не будем.
                    Мы желаем Вам и вашим близким здоровья. Спасибо, что были с нами все эти годы.
                  </p>
                </div>
              </div>
            )
          }
        </div>
      </div>
    </>
  );
};

export default CartPage;