import React from 'react';
import {Helmet} from "react-helmet";

const PayPage = () => {
  return (
    <div>
      <Helmet>
        <title>Миропристон и Миролют - Оплата</title>
        <meta name="description"
              content="Заказать Миролют, Миропристон с доставкой по Москве и всей России. Оплата картой. Медикаментозное прерывание беременности на ранних сроках."/>
      </Helmet>
      <div className='mx-3 md:container md:mx-auto pt-5'>
        <h1 className="font-bold">Информация о вариантах оплаты</h1>
        <div className='pt-5 flex flex-col'>
          <div className='pt-5 text-justify'>
            <p>
              Стоимость курьерской доставки по Москве в пределах МКАД 500 рублей (возможна оплата <b>наличными при
              получении</b>).
              <br/>
              При заказе из регионов РФ мы вынуждены брать 100% предоплату, чтобы обезопасить себя от невыкупа посылок
              при
              доставке Почтой России.
              <br/>
              Стоимость доставки фиксированная и составляет 400 рублей.
              <br/>
              <b>Пункт самовывоза</b> находится по адресу: г. Москва, м. Бунинская аллея, ул. Кадырова, 1 (требуется
              предварительное согласование с менеджером).
              <br/>
              <a className="text-blue-500 no-underline hover:underline hover:text-red-500 visited:text-purple-600"
                 href="https://yandex.ru/profile/226009762425" rel="noopener noreferrer">Карточка организации в
                Яндекс.Справочнике</a>
              <br/>
              Оплата картой возможна через сервис Юмани (Яндекс.Деньги), данный сервис взимает комиссию +3%, но есть
              возможность оплатить по карте любого банка.
              <br/>
              Также есть возможность осуществить оплату переводом на карту.
              <br/>
              Кнопка для оплаты по банковской карте (доставка включена в стоимость):
            </p>
          </div>
          <div className='pt-5 flex justify-center'>
            <iframe title='yandexMoney' src="https://yoomoney.ru/quickpay/fundraise/button?billNumber=5PJgmARE6JM.230222&" width="330"
                    height="50"></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayPage;