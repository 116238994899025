import React from 'react';
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

const CartEmpty = () => {
  return (
    <div>
      <Helmet>
        <title>Миропристон и Миролют - Корзина</title>
        <meta name="description" content="Купить Миропристон (3 таблетки) и Миролют (4 таблетки) в Москве для медикаментозного прерывания беременности. Фармаборт до 42 дней." />
      </Helmet>
      <div  className='max-w-[800px] mt-[-50px] w-full h-screen mx-auto text-center flex flex-col justify-center'>
        <h2 className='md:text-4xl sm:text-3xl text-2xl font-bold md:py-6'>
          Корзина пустая <span>😕</span>
        </h2>
        <p className='md:text-xl sm:text-md text-md'>
          Вероятней всего, Вы ещё ничего не добавляли.
          <br/>
          Для того, чтобы заказать препараты, перейдите на страницу магазина.
        </p>
        <div className='mt-5 flex justify-center'>
          <Link to="/products">
            <button className='bg-green-400 hover:bg-green-500 text-white font-bold rounded-lg py-3 px-2'>Магазин</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CartEmpty;