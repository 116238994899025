import React from 'react';
import {IProduct} from "../models/models";
import {useAppDispatch} from "../hook/redux";
import {cartSlice} from "../store/slices/cartSlice"
import {Helmet} from "react-helmet";

interface ProductDetailCardProps {
  product: IProduct;
}

const ProductDetailCard = ({product}: ProductDetailCardProps) => {
  const dispatch = useAppDispatch()
  const onClickAdd = () => {
    const item = {
      _id: product._id,
      title: product.title,
      price: product.price,
      description: product.description,
      seoDescription: product.seoDescription,
      image: product.image,
      slug: product.slug
    }
    dispatch(cartSlice.actions.addItem(item))
  }
  return (
    <>
      <Helmet>
        <title>{product.title}</title>
        <meta name="description" content={String(product.seoDescription)}/>
      </Helmet>
      <div className='md:container md:mx-auto m-2 py-4 px-6 flex flex-row justify-around border'>
        <div className='flex justify-center'>
          <img src={product.image} className='object-contain h-60 w-96' alt={product.title}/>
        </div>
        <div className='content-center px-3'>
          <h1 className='font-bold'>{product.title}</h1>
          <p className='m-2'>{product.description.text}</p>
          <p className="text-lg font-bold">{product.price} рублей</p>
          <div className='text-center'>
            <button
              onClick={onClickAdd}
              className='m-2 p-3 shadow-lg font-bold rounded-full bg-green-400 text-white hover:bg-green-500 h-[50px] w-[180px]'>В корзину
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductDetailCard;