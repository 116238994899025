import React from 'react';
import {Routes, Route} from "react-router-dom";
import MainPage from "./pages/MainPage";
import CartPage from "./pages/CartPage";
import ProductsPage from "./pages/ProductsPage";
import Navigation from './components/Navigation';
import BasePage from "./pages/BasePage";
import PayPage from "./pages/PayPage";
import ContactsPage from "./pages/ContactsPage";
import ArticleDetailPage from "./pages/ArticleDetailPage";
import ProductDetailPage from "./pages/ProductDetailPage";
import NotFoundPage from "./pages/NotFoundPage";

function App() {
  return (
    <>
      <Navigation/>
      <Routes>
        <Route path="/" element={<MainPage/>}/>
        <Route path="/cart" element={<CartPage/>}/>
        <Route path="/products/" element={<ProductsPage/>}/>
        <Route path="/products/:slug" element={<ProductDetailPage/>}/>
        <Route path="/contacts/" element={<ContactsPage/>}/>
        <Route path="/pay" element={<PayPage/>}/>
        <Route path="/base" element={<BasePage/>}/>
        <Route path="/base/:slug" element={<ArticleDetailPage/>}/>
        <Route path="*" element={<NotFoundPage/>}/>
      </Routes>
    </>
  );
}

export default App;
