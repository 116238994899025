import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ICart, IProduct} from "../../models/models";
import {calcTotalPrice} from "../../utility/calcTotalPrice";

export interface CartState {
  totalPrice: number
  items: ICart[];
}

const initialState: CartState = {
  totalPrice: 0,
  items: []
}

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addItem(state: CartState, action: PayloadAction<IProduct>) {
      const findItem = state.items.find(item => item.slug === action.payload.slug)
      if (findItem) {
        findItem.count++
      } else {
        state.items.push({
          ...action.payload,
          count: 1
        })
      }
      state.totalPrice = state.items.reduce((sum, item) => item.price * item.count + sum, 0)
    },
    minusItem(state, action: PayloadAction<string>) {
      const findItem = state.items.find((item) => item.slug === action.payload);

      if (findItem) {
        findItem.count--;
      }
      state.totalPrice = calcTotalPrice(state.items);
    },
    removeItem(state, action: PayloadAction<string>) {
      state.items = state.items.filter((item) => item.slug !== action.payload);
      state.totalPrice = calcTotalPrice(state.items);
    },
    clearItems(state) {
      state.items = [];
      state.totalPrice = 0;
    },
  }
})

export default cartSlice.reducer;