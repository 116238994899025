import React from 'react';
import {useForm, SubmitHandler} from "react-hook-form";
import {sendNotification} from "../utility/telegram";
import {useAppDispatch, useAppSelector} from "../hook/redux";
import {cartSlice} from "../store/slices/cartSlice";
import {useNavigate} from "react-router-dom";

type Inputs = {
  firstName: string,
  lastName: string,
  email: string,
  phone: number,
  address: string
};

const OrderCardForm = () => {
  const dispatch = useAppDispatch()
  const navigator = useNavigate()
  const cart = useAppSelector(state => state.cart)
  const {register, handleSubmit, formState: {errors}} = useForm<Inputs>({mode: 'onBlur'})
  const onSubmit: SubmitHandler<Inputs> = data => {
    const clearCart = cart.items.map(item => {
      return {[item.title]: `${item.count} шт.`}
    })
    clearCart.push({totalPrice: cart.totalPrice.toString()})
    const cartObject = Object.assign({}, ...clearCart)
    const fullObj = {...cartObject, ...data}
    sendNotification(fullObj, 'markdown').then(r => {
      dispatch(cartSlice.actions.clearItems())
      navigator('/')
      alert('Спасибо, Ваш заказ принят!')
    })
  }
  return (
    <form className='md:container md:mx-auto my-10' onSubmit={handleSubmit(onSubmit)}>
      <div className='h-28 flex justify-center items-center shadow-md'>
        <h4 className='uppercase text-2xl font-bold text-center'>Оформление заказа</h4>
      </div>
      <div>
        <div className='flex flex-col justify-center'>
          <label className="leading-loose text-left block mb-3 mt-5 text-sm font-thin after:content-['_*']
                            after:text-red-600 after:font-bold" htmlFor="firstName">Имя</label>
          <input className='border block h-8 p-4 box-border w-full rounded mb-2 text-sm' type="text"
                 placeholder="введите имя" {...register("firstName", {
            required: true,
            maxLength: 80,
            pattern: /^[ЁёА-я|A-Za-z]+$/i
          })}/>
          {errors.firstName && <p className='text-red-700 text-sm'>Некорректное имя</p>}

          <label className='leading-loose text-left block mb-3 mt-5 text-sm font-thin'
                 htmlFor="lastName">Фамилия</label>
          <input className='border block h-8 p-4 box-border w-full rounded mb-2 text-sm' type="text"
                 placeholder="введите фамилию" {...register("lastName", {
            maxLength: 100,
            pattern: /^[ЁёА-я|A-Za-z]+$/i
          })}/>
          {errors.lastName && <p className='text-red-700 text-sm'>Некорректная фамилия</p>}

          <label className='leading-loose text-left block mb-3 mt-5 text-sm font-thin' htmlFor="email">Email</label>
          <input className='border block h-8 p-4 box-border w-full rounded mb-2 text-sm' type="text"
                 placeholder="введите email" {...register("email", {
            required: false,
            pattern: /^\S+@\S+$/i
          })}/>
          {errors.email && <p className='text-red-700 text-sm'>Некорректный email</p>}

          <label className="leading-loose text-left block mb-3 mt-5 text-sm font-thin
                            after:content-['_*'] after:text-red-600 after:font-bold" htmlFor="phone">Телефон</label>
          <input className='border block h-8 p-4 box-border w-full rounded mb-2 text-sm' type="tel"
                 placeholder="введите телефон" {...register("phone", {
            required: true,
            pattern: /^(?:\+7|8)?9(?:\d{9})$/
          })}/>
          {errors.phone && <p className='text-red-700 text-sm'>Некорректный телефон</p>}

          <label className="leading-loose text-left block mb-3 mt-5 text-sm font-thin
                            after:content-['_*'] after:text-red-600 after:font-bold" htmlFor="address">Адрес доставки</label>
          <textarea className='border block p-4 box-border w-full rounded mb-2 text-sm'
                    placeholder="введите полный адрес доставки с индексом" {...register("address", {required: true})}/>
          {errors.address && <p className='text-red-700 text-sm'>Введите адрес доставки</p>}
        </div>
        <div className='flex justify-center items-center mt-14'>
          <button className='w-1/5 h-[40px] bg-green-400 hover:bg-green-500 text-white font-bold rounded-lg'
                  type="submit">Оформить заказ
          </button>
        </div>
      </div>
    </form>
  )
}

export default OrderCardForm;