import React from 'react';
import {ICart} from "../models/models";
import {useAppDispatch} from "../hook/redux";
import {cartSlice} from "../store/slices/cartSlice";

interface CartItemProps {
  item: ICart
  index: number
}

const CartItem = ({item}: CartItemProps) => {
  const dispatch = useAppDispatch()
  const onClickPlus = () => {
    dispatch(cartSlice.actions.addItem({
        ...item
      }),
    );
  };

  const onClickMinus = () => {
    dispatch(cartSlice.actions.minusItem(item.slug));
  };

  const onClickRemove = () => {
    if (window.confirm('Вы действительно хотите удалить товар?')) {
      dispatch(cartSlice.actions.removeItem(item.slug));
    }
  };
  return (
    <div className='flex flex-row justify-between mb-5 items-center'>
      <img className='h-[55px]' src={item.image} alt={item.title}/>
      <p>{item.title}</p>
      <button disabled={item.count === 1}
              onClick={onClickMinus}
              className='w-6 h-6 rounded-full flex justify-center items-center bg-red-200'
      >-
      </button>
      <p>{item.count} шт.</p>
      <button onClick={onClickPlus}
              className='w-6 h-6 rounded-full flex justify-center items-center bg-green-200'
      >+</button>
      <p>{item.price * item.count} руб.</p>
      <button onClick={onClickRemove}
              className='w-6 h-6 rounded-full flex justify-center items-center bg-red-200'
      >x</button>
    </div>
  );
};

export default CartItem;