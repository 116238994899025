import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "../hook/redux";
import ArticleCard from "../components/ArticleCard";
import {fetchArticles} from "../store/actions/articleActions";
import {Helmet} from "react-helmet";

const BasePage = () => {
  const dispatch = useAppDispatch();
  const {error, loading, articles} = useAppSelector(state => state.articles);

  useEffect(() => {
    dispatch(fetchArticles());
  }, [dispatch])

  return (
    <>
      <Helmet>
        <title>Миропристон и Миролют - Информация для врача и пациента</title>
        <meta name="description"
              content="Материалы для врача и пациента описывающие процесс медикаментозного прерывания беременности на ранних сроках препаратами Миропристон и Миролют."/>
      </Helmet>
      <div className='container mx-auto max-w-[760px] pt-5'>
        <h1 className="font-bold">Информация для врача и пациента</h1>
        {loading && <div className={"text-center text-lg"}>Loading...</div>}
        {error && <div className={"text-center text-lg text-red-600"}>{error}</div>}
        {!loading &&
          articles.map((article, index) => <ArticleCard key={article.slug} index={index} article={article}/>)
        }
      </div>
    </>

  );
};

export default BasePage;