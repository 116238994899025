import {AppDispatch} from "../index";
import {productsSlice} from "../slices/productsSlice";
import sanityClient from "../../sanitySetup";
import {productBySlugSlice} from "../slices/productBySlugSlice";

export const fetchProducts = () => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(productsSlice.actions.fetchProducts());
      const response = await sanityClient.fetch(
        `*[_type == 'product'] | order(price desc) { 
                _id,                  
                "description": {
                    "text": description[0].children[0].text
                },
                "image" : image.asset->url,
                title,
                price,
                "slug": slug.current,
                seoDescription
            }`
      );
      dispatch(productsSlice.actions.fetchProductsSuccess(response));
    } catch (error) {
      dispatch(productsSlice.actions.fetchProductsError(error as Error));
    }
  }
}

export const fetchProductBySlug = (slug: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(productBySlugSlice.actions.fetchProductBySlug());
      const response = await sanityClient.fetch(
        `*[_type == 'product' && slug.current == '${slug}'] {
                _id,
                "description": {
                    "text": description[0].children[0].text
                },
                "image" : image.asset->url,
                title,
                price,
                "slug": slug.current,
                seoDescription
            }`
      );
      dispatch(productBySlugSlice.actions.fetchProductBySlugSuccess(response));
    } catch (error) {
      dispatch(productBySlugSlice.actions.fetchProductBySlugError(error as Error));
    }
  }
}