import axios from "axios";
import {toMarkdownFormat} from "./objectFormat";

const telegramBotKey = process.env.REACT_APP_PUBLIC_TELEGRAM_BOT_TOKEN;
const chat_id = process.env.REACT_APP_PUBLIC_TELEGRAM_USER_ID;

export const sendNotification = async (text: object, parse_mode: string) => {
  const endpoint = `https://api.telegram.org/bot${telegramBotKey}/sendMessage`;
  await axios.post(endpoint,
    {
      text: toMarkdownFormat(new Date(Date.now()).toISOString(), text),
      parse_mode,
      chat_id
    })
}