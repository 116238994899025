const capitalize = (input: string) =>
  `${input.charAt(0).toUpperCase()}${input.slice(1)}`;

const capitalizeKey = (input: string) =>
  input.split(/(?=[A-Z])/).map(word => capitalize(word)).join(' ');

const newLineToken = '\n';

const lineBreak = newLineToken.repeat(2);

const formatTitle = (title: string) => `*${title}*`;

const formatEntry = ([key, value]: string[]) => {

  const formattedTitle = formatTitle(capitalizeKey(key));
  const formattedBody = value ? value: '(empty)';

  return `${formattedTitle}:${newLineToken}${formattedBody}`;
};

export const toMarkdownFormat = (date: string, body: Object) => {

  const formattedTitle = formatTitle(date);
  const formattedBody = Object.entries(body).map(formatEntry).join(lineBreak);
  return `${formattedTitle}${lineBreak}${formattedBody}`
};